
<main>
    <h1>Adapt</h1>
    <span>25/March/2023</span>
    
    <p>
        I'm lucky to have days where my whole life perspective changes, and even luckier when I'm able to recognize that it happens.

        <br>
        <br>

        Today, is one of those days. Up until today my life has been improvising, controlled chaos. 
        <br>
        It worked fine for small solo projects that really didn't work, it worked just fine for quick ways to make money.

        <br>
        <br>
        But today I am finally able to appreciate the beauty of re-thinking order, systems and structure from first principles. 
    </p>
    <br>

    <hr>
    
    <p>
        Recently I attended a meeting of school principals, they were discussing problems the school currently had. 

        <br>
        <br>
        I was shocked to see how  internally messed up the school organization was you'd think that after 20 years of running this show they would have the right systems in place right?
        <br>
        <br>
        That's when I realized, most adults, organizations and people in general don't have their shit together, adults are really just kids with jobs.
        <br>
        <br>
        You can never really get away from chaos, randomness exists, after all, but not constantly adapting and improving your organization's structure to deal with chaos is Sin.
        <br>
        <br>
        When I was 12 I made a promise to myself that I would never sell out, and work in a shitty job just to get paid. 
        <br>
        <br>
        Today is the day where I make another promise to myself: To do everything in my power to make my organizations efficient and good. 
        <br>
        To not make the same mistakes "adults" make, to never be like them.
        <br>
        <br>
        Maybe I am delusional, naive, and crazy for thinking like this, but fuck you if you critize me for being ambitious. 
        <br>
        <br>
        I will make mistakes, I will fail and fail, but one day I will learn and reach further than if I had just sat and done nothing.
    </p>
    <br>
    <br>
    <br>
    <br>

    </main>
    
    <style>

        main{
            width:  80vw;
            margin-left: 10vw;
        }
    
        h1{
            margin-bottom: 0.5em;
            margin-top: 10vh;
        }
    
        p {
            font-size:  22px;
            margin-top: 5vh;
        }
    
        span{
            font-size: 20px;
            color:  #828282;
            font-weight: 200;
            margin-bottom: 5vh;
        }

        a{
            color: rgb(66, 151, 216);
        }
    
    </style>