
<main>
    <h1>The Problem</h1>
    <span>11/Mar/2022</span>
    
    <p>

        I'm not sure if we will we ever cross the <a href="https://en.wikipedia.org/wiki/Great_Filter">Great Filter</a>.
        <br/>
        And if we don't, I know who is to blame: <b>Cheaters</b> and <b>Freeloaders</b>.

        <br/>
        <br/>

        I think we can split human population into 3 different categories:
        <br/>
        <br/>
        1. Group A: Those who actively work for humanity (soldiers, charity owners, dog shelter owners, etc) 
        <br/>
        2. Group B: Those who only work to benefit themselves.
        <br/>
    </p>
    <img src="https://i.imgur.com/UdZbDNF.png" alt="Group B"/>
    <p>
        Those who work only to benefit themselves are not a bad people. It's just the ones who cheat are causing most of the problems in our society.
<br/>
If you make a company <b>just</b> to become rich, and you provided value to the world its okay!
<br/>
<br/>
But if you have a monopoly just to become rich and <b>provide no innovation</b>, then you are part of the problem.
<br/>

There is nothing more evil in this world, than capable, hard working people, who only do things for themselves at the expense of everyone else.
<br/>
<br/>
3. Group C: Those who neither work nor contribute. (<b>freeloaders</b>) that make our society weak.
</p>

<img src="https://i.imgur.com/Dx8qehI.png" alt="Group C"/>

<p>
I'm not talking about people with disabilities or health problems of course, I'm talking about young, healthy people who waste most of their time on the phone.

<br/>
Some people might read the above and say "Life is not all about solving problems or contributing to society".
<br/>
<br/>
Yeah well fuck you, go live in the woods then, and chill there, let's see how long you can last there before a bear tries to eat you. 
<br/>
Everything you love so much (electricity, water, wifi, websites) was made by hardworking people who tried to make the world better.
 If you are not going to try to make the world better then go live in the woods.

<br/>
<br/>
<b>Okay so now what do we do with this information????? </b>
<br/>
<br/>
1. We help those in group 3, and give them skills so they can contribute to the world, instead of freeloading and whining about how the world is going to shit. So they can do something about it.
<br/>
<br/>

2. We remove cheaters in group 2 from positions of power.

<br/>
<br/>
</p>
<hr/>
<p>
That's the ideal solution anyways, and being realistic, this is never gonna happen. Cheaters will stay here until the gov turns authoritarian and kicks them out.
<br/>
<br/>
There is another easier, long-term solution: <b>Decentralize infrastructure.</b>
<br/>
<br/>
Let's get to a world where we don't depend on the goverment to get electricity, where free food is being produced autonomously by AI, powered by free clean energy.
<br/>
<br/>
<b>Let's get to a world where our infrastructure is automated.</b>
<br/>
<br/>
<br/>
</p>
    </main>
    
    <style>
        main{
            width:  80vw;
            margin-left: 10vw;
        }
    
        h1{
            margin-bottom: 0.5em;
            margin-top: 10vh;
        }
    
        p {
            font-size:  22px;
            margin-top: 5vh;
        }
    
        span{
            font-size: 20px;
            color:  #828282;
            font-weight: 200;
            margin-bottom: 5vh;
        }

        img{
            width: 50vw;
        }

        a{
            color: rgb(28, 117, 145);
        }

        hr{
            width: 40vw;
            opacity: 0.5;
        }
    
    </style>