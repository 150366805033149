<script>
	 import Prism from 'svelte-prism';


</script>

<main>
    <div class="coloredrainbow"></div>

	<div class="container">
		<h1 class="title">SQL Schema</h1>
        <h2>Creamos la base de datos</h2>
        <div class="prism-container">
            <Prism language="javascript">
            {` CREATE DATABASE dbproj;`}
            </Prism>
        </div>
        <h2>Creamos las tablas</h2>
        <div class="prism-container">
            <Prism language="javascript">
            {`CREATE TABLE Vuelo (
        ID varchar(255),
        Costo int,
        Duracion int,
        Destino varchar(4000),
        Partida varchar(4000),
        fecha DATE,
        PRIMARY KEY(ID)
);`}
            </Prism>
        </div>
        <div class="prism-container">
        <Prism language="javascript">
            {`CREATE TABLE Pais (
        Codigo varchar(100),
        Longitud float,
        Latitud float,
        Nombre varchar(400),
        Horario int,
        Bandera varchar(400),
        PRIMARY KEY(Codigo)
);`}
            </Prism>
        </div> 
        <div class="prism-container">
         <Prism language="javascript">
            {`CREATE TABLE Reservacion (
         ID varchar(100),
         Vuelo varchar(4000),
         Agencia varchar(4000),
         Clase varchar(4000),
         Maletas int,
         Pasajero varchar(4000), 
        PRIMARY KEY(ID)
);`}
        </Prism>        
        </div>
        <div class="prism-container">
        <Prism language="javascript">
            {`CREATE TABLE Pasajero (
         Correo varchar(100),
         Balance int,
         Nombre varchar(4000),
         Contra varchar(4000), 
        PRIMARY KEY(Correo)
);`}
        </Prism>        
        </div>
        <div class="prism-container">
        <Prism language="javascript">
            {`CREATE TABLE Agencia (
         Nombre varchar(100),
         Numero varchar(100),
         Rating varchar(4000),
         Correo varchar(4000), 
        PRIMARY KEY(Nombre)
);`}
        </Prism>        
        </div>
        <br/>
        <br/>
        <br/>
	</div>

</main>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;800&display=swap');
	
	main {
        width: 100vw;
        height:  100vh;
        display:  flex;
        flex-direction: row;
    }

    .prism-container{
        width: 80%;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    .coloredrainbow{
        background-image: linear-gradient(120deg, #D9FEF2, #CEF1FB, #CFEEFF, #DFE6FF, #EAEBFF, #fff);
        min-height:  100vh;
        width:  25vw;
        position: fixed;
    }

    h1,h2,p,span{
        font-family: 'Raleway', sans-serif;
        color:  #6B7280;
    }

    .key{
        text-decoration: underline;
    }

    h1:focus{
    	outline:  none;
    }

    h1 { 
        font-size: 25px;
        margin-left: 5vw;
        padding-top: 5vh;
    }

    h2,p{
        font-size: 20px;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    p{
        font-weight: 300;
        font-size: 15px !important;
    }

    .container{
    	width: 99vw;
    	min-height: 100vh;
    	background-color: white;
    	border-radius: 5px;
    	margin-left: 25vw;
    }

</style>