<script>
	function download(){
			window.open("https://github.com/cruzryan/GeneHacker")
	}

</script>

<h1>Adios al monopolio de SnapGene!!!</h1>

<h2>Gene Hacker</h2>

<p>El editor genetico mas avanzado del mundo 🥵🥵🥵</p>

<button on:click={download}>Descarga aqui </button>

<h2>ES GRATIS!! pero si lo usas invitame unos takis XD ntc tkm</h2>
<h2>YHLQMDLG (certificado)</h2>


<style>
		
		h1{
			margin-left: 2vw;
			margin-top: 1em;
		}

		h2,p{
			margin:  2vw;
		}

		button{
			border: 1px solid black;
			font-size: 22px;
			margin-left: 1em;
			background-color: white;
			padding:  0.5em;
			cursor:  pointer;
		}
</style>