
<main>
    <h1>Work / life</h1>

    <p>
        <br/>My life changed when I was 6 years old and watched the show Accient Aliens on history channel for the first time with my grandma in Mexico, 
        <br/>ever since that moment, I've always asked "if aliens can do it, why can't we?"
        <br/>
        <br/>Some of my acomplishments include: Winning the President's Education Award under the Obama administration when I could barely speak english, and also doing lots of competitive robotics and programing throughout my teen years. (you can ask me more via dms if you want)
        
        <br/>
        <br/>I got covid on June 2021, wanted to make my own vaccine, realized I didn't know any biology and after 15 days of reading wikipedia I fell in love with molecular biology and created created  <a href="https://github.com/cruzryan/GeneHacker">GeneHacker</a>
        <br/>
        <br/>
        I've done other stuff since then, you can check it out on my  <a href="https://github.com/cruzryan">Github Acc.</a> (I'm really bad at coding sorry)
        <br/>
        <br/>
        I also created Mexico's biggest student space robotics team: <a href="https://www.instagram.com/hiteamgenesis">Genesis</a>
        <br/>
        <br/>
        Right now I'm working on moving to SF, if you want me to join your startup shoot me an email! 


    </p>

    </main>
    
    <style>
        main{
            width:  80vw;
            margin-left: 10vw;
        }
    
        h1{
            margin-bottom: 0.5em;
            margin-top: 10vh;
        }
    
        h1:focus{
            outline: none;
        }

        a{
            color: rgb(20, 80, 90);
            text-decoration: underline;
            cursor: pointer;
        }

        p {
            font-size:  22px;
            margin-top: 5vh;
        }
    
        span{
            font-size: 20px;
            color:  #828282;
            font-weight: 200;
            margin-bottom: 5vh;
        }
    
    </style>