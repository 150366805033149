<script>
	 import Prism from 'svelte-prism';


</script>

<main>
    <div class="coloredrainbow"></div>

	<div class="container">
		<h1 class="title">SQL Schema</h1>
        <h2>Insertar</h2>
        <div class="prism-container">
            <Prism language="javascript">
            {`INSERT INTO Agencia (Nombre, Numero, Rating, Correo) VALUES ('Agencia 1', '559405995', '10', 'agencia1@gmail.com');`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`INSERT INTO Vuelo (ID, Costo, Duracion, Destino, Partida, Fecha) VALUES ('VL-304-454', 3000, 7900, 'MX', 'GER', TO_DATE('17/12/2021', 'DD/MM/YYYY'));`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`INSERT INTO Pais (Codigo, Longitud, Latitud, Nombre, Horario, Bandera) VALUES ('JP', 36.2048, 138.2529, 'Japon', 9, 'link');`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`INSERT INTO Pasajero (Correo, Balance, Nombre, Contra) VALUES ('badbunny@pr.com', 19000, 'Bad Bunny', 'YHLQMDLG');`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`INSERT INTO Reservacion (ID, Vuelo, Agencia, Clase, Maletas, Pasajero) VALUES ('RE-304-344', 'VL-304-454', 'Agencia 1', 'Primera', 3, 'badbunny@pr.com');`}
            </Prism>
        </div>

        <h2>Obtener</h2>
      	<div class="prism-container">
            <Prism language="javascript">
            {`SELECT * FROM Agencia WHERE Correo = 'agencia1@gmail.com';`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`SELECT * FROM Vuelo WHERE ID = 'VL-304-454';`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`SELECT * FROM Pais WHERE Codigo = 'JP';`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`SELECT * FROM Pasajero WHERE Correo = 'badbunny@pr.com';`}
            </Prism>
        </div>

        <div class="prism-container">
            <Prism language="javascript">
            {`SELECT * FROM Reservacion WHERE ID = 'RE-304-344';`}
            </Prism>
        </div>
        <br/>
        <br/>
        <br/>
	</div>

</main>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;800&display=swap');
	
	main {
        width: 100vw;
        height:  100vh;
        display:  flex;
        flex-direction: row;
    }

    .prism-container{
        width: 80%;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    .coloredrainbow{
        background-image: linear-gradient(120deg, #D9FEF2, #CEF1FB, #CFEEFF, #DFE6FF, #EAEBFF, #fff);
        min-height:  100vh;
        width:  25vw;
        position: fixed;
    }

    h1,h2,p,span{
        font-family: 'Raleway', sans-serif;
        color:  #6B7280;
    }

    .key{
        text-decoration: underline;
    }

    h1:focus{
    	outline:  none;
    }

    h1 { 
        font-size: 25px;
        margin-left: 5vw;
        padding-top: 5vh;
    }

    h2,p{
        font-size: 20px;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    p{
        font-weight: 300;
        font-size: 15px !important;
    }

    .container{
    	width: 99vw;
    	min-height: 100vh;
    	background-color: white;
    	border-radius: 5px;
    	margin-left: 25vw;
    }

</style>