
<script>

    let sur = false;
    let c = 0;
    let color = '#000000';

    function col() {
        color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
    function car(){
        col()
        c++;
        if(c == 10){
            sur = true;
        }

    }

</script>


<main>
    <button  style="color: {color}" on:click={() => car()}>tqm carol (dale click)</button>

    {#if sur == true}
        <h1>💘</h1>
    {/if}
    
</main>


<style>

    main{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100vw;
        height: 100vh;
    }

    button{
        border: 0px;
        font-size: 22px;
        background-color: white;
        
    }
</style>