
<main>
    <h1>20</h1>
    <span>20/November/2022</span>
    
    <p>
        Who are you? really, are you what you believe? are you what happens to you? are you your thoughts?
        <br>
        <br>
        You might be tempted to answer, "I am what I think", I think thats beyond false, most of the time you cant even control what you think.
        <br>
        You cant even control how you behave under certain situations, at all. 
        <br>
        <br>

        "Oh I can control my actions" you might say.
        <br>
        If you really can, why do you procrastinate? why do you lie? why do you do things you dont like?        
        <br>
        <br>
    </p>
    <hr>
    <p>
        To be honest I dont have an answer either. But turning 20 really made me question my life choices up to this point.
        <br>
        <br>
        "If nobody has a gun to your head, then you are free" - paraphrased from george hotz
        <br>
        <br>
        You know what, I am free. 
        <br>
    </p>
    <br>
    <hr>
    <p>
        Maybe the biggest lesson I learned all these 20 years is to just to <a href="https://twitter.com/realGeorgeHotz/status/1591821004816650240">hack everything</a>. (have fun, explore and do whatever you feel its right).
    </p>
    
    <p> 
        Some people think that I do all this crazy shit just to impress people, to gain fame, money or for another terminal value...
        <br>
        <br>
        But that's just not who I am, everything i do is because i wanna have fun or i think its cool, like drake said:
        <br>
        <br>
        <b>"The moment I stop having fun with it, i'll be done with it"</b>
        <br>
        <br>
        That's my life, except that whatever dumb hobby i pick up, be it programming, robotics, or something else, i go full hardcore mode and obsess over it.
        <br>
        <br>
        I wanna work on time travel, artificial intelligence and hardcore stuff, not because I want Nobel prize. Instead, I will do it because it's cool and im bored and because it's hard. 
        <br>
        <br>
        I believe I am gonna make a lot of money just being myself, and doing cool stuff.

        And if I don't make any money... I would rather have $0, be homeless and work on cool random stuff, than being a famous millionare and a sellout that tries to fit in.
        
    </p>
    <br>
    <br>
    <br>
    <br>

    </main>
    
    <style>

        main{
            width:  80vw;
            margin-left: 10vw;
        }
    
        h1{
            margin-bottom: 0.5em;
            margin-top: 10vh;
        }
    
        p {
            font-size:  22px;
            margin-top: 5vh;
        }
    
        span{
            font-size: 20px;
            color:  #828282;
            font-weight: 200;
            margin-bottom: 5vh;
        }

        a{
            color: rgb(66, 151, 216);
        }
    
    </style>