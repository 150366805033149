
<main>
<h1>Games</h1>
<span>10/Oct/2021</span>

<p>
	I think life is all about figuring out what games to play (if you examine your life constantly), of course some people aren't able to see <br/>
	the different games they can play so they just live their life day-to-day yknow. <br/>
<br/>
	Anyways, I've figured out the games I'm good at are:
<br/><br/>
	1. Making the world better through engineering.<br/>
	2. Social status games.<br/>
<br/>
	Sadly, playing social status games makes me unhappy and often miserale (unless im constantly winning). Unlike game (1) because even if I fail at doing the task (which hasn't really happened since 2016) I can still keep going.<br/>  
<br/>

	I think the best way to play social status games is just to keep doing cool shit, keep it real, and being careful of not selling out.
<br/>
<br/>

	Selling out (doing stuff just for clout/fame) leads to living a miserable life, I will never do it. 
<br/>
<br/>
	To the teenagers out there: Don't do dumb shit just to gain attention, do cool stuff you like instead!!!!! 
<br/>
	Get famous because of your skills, not from being a sell out.
<br/>
<br/>
<span>
"Most people in my position get complacent<br/>
Wanna come places with star girls, and they end up on them front pages<br/>
I'm quiet but I just ride with it<br/>
Moment I stop havin' fun with it, I'll be done with it"
</span><br/> - Drake
<br/> <br/>
Sometimes I ask myself... is posting blogs even worth it? Is anyone reading this? 
<br/><br/>
Then I remember that doing stuff because you think other people will like it is a horrible mindset to have.
<br/><br/>
I write blogs for the same reason I do anything else: Because I like to do it, so ill just keep posting on here.
<br/><br/>
<br/>
<br/>
</p>

</main>

<style>

	span {
		/*font-style: italic;*/
		color:  gray;
	}

	main{
		width:  80vw;
		margin-left: 10vw;
	}

	h1{
		margin-bottom: 0.5em;
		margin-top: 10vh;
	}

	p {
		font-size:  22px;
		margin-top: 5vh;
	}

	span{
		font-size: 20px;
		color:  #828282;
		font-weight: 200;
		margin-bottom: 5vh;
	}

</style>