
<main>
<h1>Brain</h1>
<span>26/Oct/2021</span>

<p>
"When it comes to healing the mind, placebo effects are completely valid." - Naval. <br/><br/>

There is so much advice out there that tries to change your mind, we even have a word for it now, "mindsets". <br/>

All of them try to impose a view of reality in your head, and some even manage to change your view of the world.<br/>

For the longest time I've been looking for advice on how I should live my life, what things should I believe, how should I structure my brain?<br/>
<br/>
But I had a breakthrough last night...what if I create my own mindset from first principles? and optimize for max(happiness, productivity, altruism, peace, living_a_good_life), where living_a_good_life is an 80 year old version of me looking back and being nostalgic about all the cool things I did and the joy I had.<br/><br/>

Well that would be amazing, wouldn't it?<br/><br/>

So here is my new mindset from scratch:<br/><br/>

</p>

<h2>Part 1: Emotions</h2>

<p>
	I. We assume emotions cannot be controlled, but understood: This is an old trick from meditation, more precisely "mindfulness". We can't just will ourselves into feeling happy, or not feeling sad. But we can, understand our emotions. We can ask "why?" until we understand.  
<br/><br/>
	II. We assume we can't run away from emotions, we must fight back: You cannot distract yourself from depression, or anxiety, or a bad emotion. You must fight back and be proactive. Example: Are you feeling sad because you haven't done anything all day? Don't distract yourself to stop feeling sad, instead, stand up and get some work done. Even 5 minutes of work!
<br/><br/>
	III. Assume ignorance before malice: this is straight forward, assume someone was naive in their way of acting instead of whatever evil story you have in your head.
<br/><br/>
	IV. We assume our brain is split in two parts: Thinking and Feeling. And you can only live a good life if both of them work together. If you become a thinker, then you will abstract things so much, that you're not going to feel happy anymore. If you become a feeler, then your actions are going to be controlled by your emotions, and that will make you miserable (procrastinating, watching funny videos all day, etc...).
	Both sides of you must work together to live a good life. But how? (Read rule V.)
<br/><br/>
	V. Thinking regulates Feeling: That does not mean "Only thinking" or "only sacrificing the future for the present". Instead, it means letting the emotion part of you roam free, and whenever you are going to take an action that will negatively affect your future, allowing the thinking part of you to take control of your brain and correct it.
</p>
<br/><br/>
<h2>Part 2: Thinking</h2>
<p>
	I. We assume the way we think is controlled by what information enters our brain: This is also straightforward, you are the the information you consume.
	If you wanna complain all day, then read tweets/posts of people who complain all day. If you wanna be happy, listen to happy people. 
<br/><br/>
	II. Mindset shift: We assume we can shift mindsets temporally, or as regular people call it "Put yourself in someone else's shoes". This is good, just be careful, shifting mindsets for too long might make it permanent.
<br/><br/>
</p>

</main>

<style>
	main{
		width:  80vw;
		margin-left: 10vw;
	}

	h1{
		margin-bottom: 0.5em;
		margin-top: 10vh;
	}

	p {
		font-size:  22px;
		margin-top: 5vh;
	}

	span{
		font-size: 20px;
		color:  #828282;
		font-weight: 200;
		margin-bottom: 5vh;
	}

</style>
