<h1>hi, this is technically somewhere online</h1>

<h3>I just thought it would be really funny to use my own webpage for this</h3>

<p> But yeah, I'm really excited to work on cool technology with like-minded people in a place I have never been to (Dubai), I'm gonna get a lot of things done

    <br>
    <br>
    I love to build
</p>


<style>

    h1{
        margin-top: 10vh;
    }

    h1,h3,p{
        margin-left: 10vw;
    }

    p{
        margin-top: 3em;
    }
</style>