
<main>
    <h1>Agency</h1>
    <span>10/August/2023</span>
    
    <p>
        This essay is an attempt to explain my new mindset.
        <br>
        <br>
        People can have many attributes, like being funny, smart, kind, or caring and yet there is a hidden attribute nobody talks about... <b>Agency.</b> 
        <br>
        <br>
         Having agency means being able to bend the world 🌎 to your will, to get what you want.
        <br>
        <br>
         
         A synonym of agency is probably "Your ability to <b>find a way</b> to get anything done, fast"
        <br>
        <br>
            Agency is a spectrum, you probably have enough agency to find a snack you want on the store. But if you were tasked with finding the best underground street rapper in Taiwan and having him jump off a skyscrapper with a parachute, could you do it? 
    </p>
    <br>

    <hr>
    <p>
        Now that we understand what agency is, here is my best attempt to describe how to become more agent:
        <br>
        <br>

        <b>1. Want more things</b>
        <br>
        <b>Getting used to things is the #1 agency killer.</b> You must never be satisfied.

        <br>
        <br>
        Life can always be better, look for ways to improve it in small and big ways. 
        <br>
        <br>
        
        You could be eating your favorite food right now. Your room could look better. You could have more money.
        
        <br>
        <br>
        Even the little things matter, your shoes are dirty. Haven't you had the same toothbrush for a while? When are you getting a better one? Don't you want to feel true love from a partner?
        
        <br>
        <br>
        Life can always be better.
        <br><br>
        <b>2. 0% lie policy</b>
        <br>
        They are their own worst critic, they know the easiest person to fool is themselves.
        <br> They are the ones who inspect and call out the lies within their life and themselves. No, everything is not okay. You are not fine. You are not growing. And you are broke. And you wish you weren't.
        <br><br>They don't think that just to feel bad. They try to be brutally honest. <b>You can't fix something unless you acknowledge its broken.</b>
        <br>
        <br>
        They also aren't afraid to call shit out, or criticise their boss in their face, when their team isn't working, or something isn't right, you will hear about it from them first.
        <br> <br>

        <b>3. They have a high pain tolerance but also don't suffer more than they have to. </b>
        <br>
        They will find a way to aliviate suffering as soon as possible. Even in seconds. 
        <br>
        <br>
        If it's too hot, they will find a fan. 
        <br>
        If the room is too loud, they will tell people to shut up instantly. (in a kind way)
        <br>
        If they crave candy, they will find a way to get it, instantly.
        <br>
        <br>
        <b>They don't let lazyness stop them from being happy. </b>

        <br>
        <br>
        <b>4. They increase their luck. (Probability of something good happening to them)</b>
        <br><br>
        They always know where to be. 🗺️📌 They are always at the right place. 🗺️📌 They setup themselves in positions to be lucky. 
        <br>
        They are ALWAYS where the action is. Never second. Always first. Mentally and PHYSICALLY. move closer to the fire. 🔥🚒
        <br>
        <br>
        They say "yes" quicker than everyone else because they think faster about the outcomes of saying yes and they don't let shyness slow them down or stop them.
        Therefore they get opportunities first. And it accumulates. Making them get more and more lucky. 

        <br><br>
        They know when to say no. Not because it "feels right", before saying no they can articulate why 80% of the time. They think it through. 

        <br>
        <br>
        <b>5. They look calm and happy all the time. 😀👍</b>
        <br>
        Always chill and laid back. Looking relaxed even in high stress situations. Even faking it by doing things they <i> wouldn't do </i> while stressed. 

        Always make the room confortable. 

        Being happy and generally positive while solving hard shit pays off. <b> Makes it more efficient for you and everyone else. </b>

        <br>
        <br>
        <b>6. Bored = Work</b>
        <br>
        Another lesson is that everything happens in real time, when they are bored, instead of opening tiktok or reels, they get to work. 
        <br>
        <br>
        They don't wait for the "perfect time" to make progress. They can acomplish a LOT in 5 minutes. 
        <br>
        <br>
        <b>7. They believe everything is possible.</b>
        <br>
        No task is too big, fuck the haters.
        <br>
        <br>
        After all, how could they acomplish anything if they don't believe its possible with every cell of their body?
        <br><br><br>

        TLDR: Ultimately. The mindset everyone with agency must have is the following: <b>Everything is possible.</b> 
        <br>
        Spend every second you're alive looking for something you want, then go and get it. Then get some more after that! 

    </p>    


    <br>

    <br>
    <br>
    <br>
    <br>

    </main>
    
    <style>

        main{
            width:  80vw;
            margin-left: 10vw;
        }
    
        h1{
            margin-bottom: 0.5em;
            margin-top: 10vh;
            outline: none;

        }
    
        p {
            font-size:  22px;
            margin-top: 5vh;

        }
    
        span{
            font-size: 20px;
            color:  #828282;
            font-weight: 200;
            margin-bottom: 5vh;
        }

        a{
            color: rgb(66, 151, 216);
        }

        hr{
            width: 40vw;
            opacity: 0.5;
        }
    
    </style>