<script>
    import { Link } from "svelte-navigator";

    let links = [{t: "E/R Diagram", ll: "/databases-project/er-diagram"}, {t: "Conversion to Relations", ll: "/databases-project/relations"},{t: "SQL Schema", ll: "/databases-project/sql-schema"},{t: "SQL interaction", ll: "/databases-project/sql-interaction"},{t: "Simulator source code", ll: "NONE"}]

</script>
<main>

    <div class="header">
        <h1 class="database-proj">Database Project:</h1>
        <h1 class="proj-name">Airline System</h1>
    </div>

    <div class="container-box">
        <h1 class="run-txt">Run simulator</h1>
        <div class="play-container">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="#AEFCDF">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
            </svg>
        </div>
    </div>

    <div class="container-links">
        <h1 class="links-title">Quick links</h1>
        <div class="links">

            {#each links as l }
            {#if l.ll == "NONE"}
            <div on:click={() => window.open("https://github.com/cruzryan/Databases-Airline")} class="llink">
                <div class="llink-img">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#6B7280">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                </div>
                <h2 class="llink-txt">{l.t}</h2>
            </div>
            {:else}
            <Link to={l.ll}>
            <div class="llink">
                <div class="llink-img">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#6B7280">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                </div>
                <h2 class="llink-txt">{l.t}</h2>
            </div>
            </Link>
            {/if}
            {/each }

        </div>
    </div>

    <div class="student-names">
        <h1>Ryan Nathanael Cruz Barragan</h1>
        <h1>Adrian Bernal Gallardo</h1>        
        <h1>Hemiliano Villagomez Hernandez</h1>        
        <h1>Ricardo de Jesus Luna Gomez</h1>        
    </div>

</main>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;800&display=swap');

    h1,h2{
        font-family: 'Raleway', sans-serif;
    }    

    main {
        width: 100vw;
        height:  100vh;
    }

    .student-names{
        display:  flex;
        flex-direction: column;
        margin-top: -25vh;
    }

    .student-names h1{
        font-weight: 500;
        font-size: 18px;
        margin-left: 60vw;
        opacity: 0.7;
        margin-top: 0.5em;
    }

    .container-links{
        display: flex;
        flex-direction: column;
        margin-left: 30vw;

    }

    .links-title{
        color: #7B82FC;
        font-size: 22px;
        margin-top: 2em;
    }

    .links{
        display:  flex;
        flex-direction: column;
    }

    .llink{
        display:  flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1em;
    }

    .llink-txt{
        font-size: 18px;
        color:  #6B7280;
        cursor:  pointer;
    }

    .llink-txt:hover{
        color: #40444C;
    }

    .llink-img{
        width:  18px;
        height:  18px;
        margin-right: 0.5em;
    }

    .container-box{
        position: absolute;
        width: 250px;
        height: 300px;
        margin-top: -150px;
        margin-left: 5vw;
        background-color: white;
        /*border:  1px solid black;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
        justify-content: space-evenly;
    }

    .play-container{
        width:  60px;
        height:  60px;
        cursor:  pointer;
    }

    .run-txt{
        opacity: 0.8;
        font-size: 22px;
        font-weight: 500;
    }

    .header{
        width: 100vw;
        height: 55vh;
        background-image: linear-gradient(120deg, #D9FEF2, #CEF1FB, #CFEEFF, #DFE6FF, #EAEBFF, #fff);
    }

    .header h1{
        font-size:  30px;
        margin-left: 5vw;
    }

    .database-proj{
        padding-top: 10vh;
    }

    .database-proj:focus{
        outline:  none;
    }

    .proj-name{
        padding-top: 0.5em;
        opacity: 0.5;
    }

</style>