<script>
	
</script>

<main>
    <div class="coloredrainbow"></div>

	<div class="container">
		<h1 class="title">Conversion to Relations</h1>

		<h2 class="subtitle">Relations</h2>

        <p class="txt">Reservacion(<span class="key">ID</span>, Vuelo, Agencia, Clase, Maletas, Pasajero)</p>
        <p class="txt">Vuelo(<span class="key">ID</span>, Costo, Duracion, Destino, Partida, Fecha)</p>
        <p class="txt">Pais(<span class="key">Codigo</span>, Longitud, Latitud, Nombre, Horario, Bandera)</p>
        <p class="txt">Pasajero(<span class="key">Correo</span>, Balance, Nombre, Contra)</p>
		<p class="txt">Agencia(<span class="key">Nombre</span>, Rating, Numero, Correo)</p>

		<h2 class="subtitle">Functional dependencies</h2>
        <p class="txt">ReservasionID -> Vuelo, Agencia, Clase, Maletas, Pasajero</p>
        <p class="txt">VueloID -> Costo, Duracion, Destino, Partida, Fecha</p>
        <p class="txt">CorreoPasajero -> Correo, Balance, Nombre, Contra </p>
        <p class="txt">NombreAgencia -> Rating, Numero, CorreoAgencia</p>


        <br/>
        <br/>
        <br/>
	</div>

</main>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;800&display=swap');
	
	main {
        width: 100vw;
        height:  100vh;
        display:  flex;
        flex-direction: row;
    }

    .coloredrainbow{
        background-image: linear-gradient(120deg, #D9FEF2, #CEF1FB, #CFEEFF, #DFE6FF, #EAEBFF, #fff);
        min-height:  100vh;
        width:  25vw;
        position: fixed;
    }

    h1,h2,p,span{
        font-family: 'Raleway', sans-serif;
        color:  #6B7280;
    }

    .key{
        text-decoration: underline;
    }

    h1:focus{
    	outline:  none;
    }

    h1 { 
        font-size: 25px;
        margin-left: 5vw;
        padding-top: 5vh;
    }

    h2,p{
        font-size: 20px;
        margin-left: 5vw;
        margin-top: 5vh;
    }

    p{
        font-weight: 300;
    }

    .container{
    	width: 99vw;
    	min-height: 100vh;
    	background-color: white;
    	border-radius: 5px;
    	margin-left: 25vw;
    }

</style>