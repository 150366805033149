
<main>
<h1>How to be happy</h1>
<span>15/Sep/2021</span>

<p>

	I've spent half of 2021 figuring out how to be happy and I came to this method from first principles.
	<br/>
This is not some self-help bullshit, this actually works. 
	<br/><br/>
	Happiness boils down to:  (please read each line slowly, pause to think)
	<br/>
	1. Having self control to make your future better<br/>
2. Being grateful for the present<br/>
3. Being careful to who you're listening to (ignore losers)<br/>
4. Having compassion for other people. (Not judging, instead understanding why they did what they did. Putting yourself on their shoes.)<br/>
5. Forgiving yourself for your past mistakes, begin again.<br/>
6. Being mindful of your emotions, when you are feeling sad analyze, and understand the thing making you sad. Then Stop doing it<br/>
7. Socializing on moderation. (Exposing yourself to social media or IRL just enough to enjoy it, but not enough to become miserable)<br/>
8. Having something to look forward to, and it has to be something out of your confort zone (cold shower, hard work out, new diet, etc)<br/>
9. Not playing status games, IRL or online. Status games make you miserable.<br/>
10. Do not sell out.

<br/>
<br/>
Signs you're happy:
<br/>
1. You're in peace. <br/>
2. You're happy for the success of others. <br/>
3. You understand people.<br/>
4. You are in control of your mind. <br/>
5. You are optimistic about your future. <br/>
When was the last time you truly laughed? Do you remember when you are 8 and didn't worry about instagram or politics? Want to bring it back? You can do it! just unfollow everyone that is not making you happy. That simple. You don't owe them anything. (yes that might include your favorite youtubers or instagram accounts) Keeping up with the news is not worth it if your wellbeing is not good! Listen to some positive shit instead!!!<br/>
<br/>
<br/>

</p>
</main>

<style>
	main{
		width:  80vw;
		margin-left: 10vw;
	}

	h1{
		margin-bottom: 0.5em;
		margin-top: 10vh;
	}

	p {
		font-size:  22px;
		margin-top: 5vh;
	}

	span{
		font-size: 20px;
		color:  #828282;
		font-weight: 200;
		margin-bottom: 5vh;
	}

</style>
