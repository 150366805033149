
<main>
<h1>What is everyone doing?</h1>
<span>7/Sep/2021</span>

<p>At what point did we stop caring about time travel? 

	<br/> At what point did we decide that staring at facebook, twitter or tiktok for HOURS was normal? Really?

<br/>
	<br/> I recently heard that asgardiian "magic" from the thor universe was really just some extremely advanced tech and it really stuck with me...
<br/>
	at this rate of improvement, are we really gonna get there? within my life time?
	How much longer do we have to wait? 300 years? 
<br/>
<br/>
	I honestly don't know, most people don't even try to come up with ideas on how to make the world better, they just repeat shit other people say.
<br/>
	And those trying to "innovate" by creating the next facebook! or next tiktok!
	won't get us there. because they are too busy thinking of how to make more money, fuck them.
<br/>
	At what fucking point did time travel research become taboo? why is there noone taking teletransportation seriously?
<br/>
	I get that most people don't have the knowledge or interest to do such research, but even within the physics community talking about it is taboo. 
<br/>
	Heck! Talk to physics people about aliens and they either take you as a fool or spit the same shit othe physics people say like the Fermi Paradox.
<br/><br/>
	When are we getting Tony Stark IronMan suits? or Dr. Strange kindof magic? 
</p>
</main>

<style>
	main{
		width:  80vw;
		margin-left: 10vw;
	}

	h1{
		margin-bottom: 0.5em;
		margin-top: 10vh;
	}

	p {
		font-size:  22px;
		margin-top: 5vh;
	}

	span{
		font-size: 20px;
		color:  #828282;
		font-weight: 200;
		margin-bottom: 5vh;
	}

</style>