
<main>
<h1>Everything repeats</h1>
<span>9/Sep/2021</span>

<p>When I was litte I used to look at adults and think... damn, they are are boring as hell. How can they live these mediocre lives?
<br/>
How did they get to that point in life? living miserable, worrying about the same shit every month. Have we learned nothing from history? Why is everyone okay with living like this?
<br/>
<br/>
I used to think future generations would be different, I used to believe that my generation will change this! Surely... we will notice their mistakes and live a much healthier and happier lifes right?
<br/>
I was wrong, all my friends still make the same mistakes, and think that making those mistakes is part of "growing up" fuck you 
<br/>
Yeah!! Time to box myself into a political party & ideology!! Time to get a minimum wage job!! of course... lets stick to this toxic relationship we have! after all... we love eachother... right?? *gets divorced 19 years later*
<br/>
<br/> 
Are we ever gonna learn from history? when will the cycle stop? The only reason society has changed is because technology has improved, but the underlaying games we play are the same: status, money, etc...

<br/> 
<br/> 
I don't think there is anything I can do to help here, other than improving technology. I really hope humanity gets their shit together. But at this rate of improvement it's going to take centuries.

</p>
</main>

<style>
	main{
		width:  80vw;
		margin-left: 10vw;
	}

	h1{
		margin-bottom: 0.5em;
		margin-top: 10vh;
	}

	p {
		font-size:  22px;
		margin-top: 5vh;
	}

	span{
		font-size: 20px;
		color:  #828282;
		font-weight: 200;
		margin-bottom: 5vh;
	}

</style>