<script></script>

<main>
  <div class="header">
    <img class="logo" alt="ryanfans" src="https://i.imgur.com/rnntXdE.png" />
  </div>


    <div class="card">

        <div class="card-pp">
            <img alt="me" src="https://i.imgur.com/BhYHTBf.jpg"/>

            <div class="data">  
                <h1>@ryanfans</h1>
                <h2>Vive cerca de ti (33min)</h2>
                <br/>
                <h3>Activo (hace 1 hora)</h3>
            </div>
        </div>

        <h2 class="desc">Hola soy Ryan y la vdd ocupo varo$$$ jasjasjas hago paginas web por $4-6k quedan listas 24h y se ven chidas, no como esta que hice en 10min</h2>

        <button>SUBSCRIBETE - $109MXN /mes</button>
        <br/>

        <h3>Mandame mensaje por insta: @ryancruzb si quieres una pag</h3>

    </div>

</main>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@-webkit-keyframes fontbulger  {
   0%, 100% {
    height: 100px;
      width: 200px;
    }
   
   50% {
    height: 110px;
      width: 210px;
   }

}
    h1,h3{
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: rgb(39, 39, 39);
    }
    h3{
        color: rgb(74, 74, 74);
    }

    main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  .header {
    width: 100vw;
    height: 10vw;      
  }

  button{
      background-color: #00AFF0;
        font-family: 'Roboto', sans-serif;
        color: white;
        width: 70%;
        height: 3em;
        border-radius: 15px;
        border: none;
        cursor: pointer;
        font-size: 17px;
        margin-bottom: 2em;
  }

  .header .logo{
      height: 100px;
      width: 200px;
      margin-top: 5vh;
      margin-left: 3vw;
      animation:fontbulger 2s infinite;
    }


    .desc{
        width: 70%;
        margin-top: 5vh;
        font-size: 19px;
        margin-bottom: 2em;
    }

  .card{
      width: 50vw;
      height: 80vh;
      border-radius: 15px;
      box-shadow: 0px 0px 3px rgb(228, 228, 228);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .card-pp{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 5vh;
  }

  .card-pp img{
      width: 200px;
      height: 200px;
      border-radius: 100px;
      margin-left: 5vw;
  }

  .data{
      margin-left: 3vw;
  }

</style>
