<script>

import { Route, Router } from "svelte-navigator";
import Blogs from "./Blogs.svelte";
import Work from "./Work.svelte";
import Contact from "./Contact.svelte";
import Home from "./Home.svelte";
import Ryanfans from "./Ryanfans.svelte";
import GeneHacker from "./GeneHacker.svelte";

import Blog1 from "./Blogs/Blog1.svelte";
import Blog2 from "./Blogs/Blog2.svelte";
import Blog3 from "./Blogs/Blog3.svelte";
import Blog4 from "./Blogs/Blog4.svelte";
import Blog5 from "./Blogs/Blog5.svelte";
import Blog6 from "./Blogs/Blog6.svelte";
import Blog7 from "./Blogs/Blog7.svelte";
import Blog8 from "./Blogs/Blog8.svelte";
import Blog9 from "./Blogs/Blog9.svelte";

//BD
import BD from "./BD.svelte";
import Relations from "./BD/Relations.svelte";
import SQLSchema from "./BD/SQLSchema.svelte";
import SQLinteraction from "./BD/SQLinteraction.svelte";
import ER from "./BD/ER.svelte";
import Carol from "./Carol.svelte";
    import Blog10 from "./Blogs/Blog10.svelte";
    import SomewhereOnline from "./SomewhereOnline.svelte";

</script>

<main>
  <Router>

  <Route path="databases-project/er-diagram">
    <ER/>
  </Route>

  <Route path="databases-project/sql-interaction">
    <SQLinteraction/>
  </Route>

  <Route path="databases-project/relations">
    <Relations/>
  </Route>

  <Route path="databases-project/sql-schema">
    <SQLSchema/>
  </Route>

  <Route path="databases-project">
    <BD/>
  </Route>
	
  <Route path="somewhere_online">
		<SomewhereOnline/>
  </Route>

	<Route path="contact">
		<Contact/>
    </Route>

	<Route path="blogs">
		<Blogs/>
    </Route>

  <Route path="work">
    <Work/>
    </Route>

  <Route path="fans">
		<Ryanfans/>
  </Route>

  <Route path="what_is_everyone_doing">
  	<Blog1/>
  </Route>

  <Route path="everything_repeats">
    <Blog2/>
  </Route>

  <Route path="happy">
    <Blog3/>
  </Route>

  <Route path="games">
    <Blog4/>
  </Route>

  <Route path="brain">
    <Blog6/>
  </Route>

  <Route path="problem">
  	<Blog7/>
  </Route>

  <Route path="20">
  	<Blog8/>
  </Route>

  <Route path="adapt">
  	<Blog9/>
  </Route>

  <Route path="agency">
  	<Blog10/>
  </Route>

  <Route path="gh">
    <GeneHacker/>
  </Route>

  <Route path="carol">
    <Carol/>
  </Route>

    <Route path="/">
		<Home/>
    </Route>

  </Router>
</main>
